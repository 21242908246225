<template>
  <v-form ref="form" style="padding-top: 20px !important; padding-bottom: 20px !important">
    <!-- Honeypot -->
    <v-text-field
      v-model="honeypot"
      outlined
      dense
      label="Nimi"
      hide-details
      style="display: none"
    />
    <v-row
      :style="{
        fontFamily: currentForm.styles.fontFamily,
      }"
      dense
      :class="[
        showGrid ? 'form-body' : '',
        currentForm.styles.align == 'center'
          ? 'justify-center'
          : currentForm.styles.align == 'left'
          ? 'justify-start'
          : 'justify-end',
      ]"
    >
      <v-col
        :cols="width.cols"
        :sm="width.sm"
        :md="width.md"
        :lg="width.lg"
        :xl="width.xl"
        :style="currentForm.styles.border ? borderStyle : ''"
        :class="formPadding"
      >
        <v-row v-for="(row, rowIndex) in rows" :key="rowIndex + 'a'" dense class="row-container">
          <!-- Row number -->
          <div v-if="showGrid" class="row-number">Rivi {{ rowIndex + 1 }}</div>

          <v-col
            v-for="(col, colIndex) in row.cols"
            :key="colIndex + 'b'"
            :cols="colsArr(col.colsString)[0]"
            :sm="colsArr(col.colsString)[1]"
            :md="colsArr(col.colsString)[2]"
            :lg="colsArr(col.colsString)[3]"
            :xl="colsArr(col.colsString)[4]"
            :class="{ 'text-center': col.textCenter }"
            class="column"
          >
            <div v-if="showColumn(col)">
              <!-- Logo -->
              <div
                v-if="col.fieldType === 'logo' && (logo || isPreview)"
                :style="{ display: 'flex', 'justify-content': col.align }"
                :class="colPadding(col.padding)"
              >
                <v-img
                  v-if="logo"
                  :src="imageUrl"
                  contain
                  :max-width="`${col.maxWidth}px`"
                  @error="reloadImage"
                >
                </v-img>

                <p v-else-if="!logo && isPreview">Lataa logo tilin asetuksista...</p>
              </div>

              <!-- Button / link -->
              <v-btn
                v-if="col.fieldType === 'button'"
                :color="currentForm.styles.colors.fieldColor"
                class="white--text"
                @click="download(col.link)"
              >
                {{ col.text }}</v-btn
              >

              <!-- H1 -->
              <h1
                v-if="col.fieldType === 'h1'"
                :style="`color: ${currentForm.styles.colors.textColor}`"
              >
                {{ col.text }}
              </h1>
              <!-- H2 -->
              <h2
                v-if="col.fieldType === 'h2'"
                :style="`color: ${currentForm.styles.colors.textColor}`"
              >
                {{ col.text }}
              </h2>
              <!-- H3 -->
              <h3
                v-if="col.fieldType === 'h3'"
                :style="`color: ${currentForm.styles.colors.textColor}`"
              >
                {{ col.text }}
              </h3>
              <!-- P -->
              <p
                v-if="col.fieldType === 'p'"
                :style="`color: ${currentForm.styles.colors.textColor}`"
              >
                {{ col.text }}
              </p>

              <!-- name -->
              <label
                v-if="currentForm.styles.showNameAbove && col.name && col.fieldType != 'submit'"
                >{{ col.name }}</label
              >

              <!-- V Text field -->
              <v-text-field
                v-if="col.fieldType === 'text' || col.fieldType === 'email'"
                v-model="form.fields[col.label]"
                class="form-field"
                :label="currentForm.styles.showNameAbove ? '' : col.name"
                :outlined="col.fieldStyle == 'outlined' ? true : false"
                :style="fieldStyles"
                :solo="col.fieldStyle == 'solo' ? true : false"
                :rules="textOrEmailRules(col)"
                dense
                :hide-details="!currentForm.styles.showError"
                @input="forceUpdate($event)"
              />

              <!-- V Text number -->
              <v-text-field
                v-if="col.fieldType === 'number'"
                v-model.number="form.fields[col.label]"
                class="form-field"
                type="number"
                :style="fieldStyles"
                :label="currentForm.styles.showNameAbove ? '' : col.name"
                :outlined="col.fieldStyle == 'outlined' ? true : false"
                :solo="col.fieldStyle == 'solo' ? true : false"
                :rules="col.required ? validations.anyNumber : []"
                dense
                v-prevent-paste
                :hide-details="!currentForm.styles.showError"
                @input="forceUpdate($event)"
              />

              <!-- V Textarea -->
              <v-textarea
                v-if="col.fieldType === 'textarea'"
                v-model="form.fields[col.label]"
                class="form-field"
                :label="currentForm.styles.showNameAbove ? '' : col.name"
                :outlined="col.fieldStyle == 'outlined' ? true : false"
                :solo="col.fieldStyle == 'solo' ? true : false"
                :rules="col.required ? validations.required : []"
                :style="fieldStyles"
                rows="3"
                dense
                :hide-details="!currentForm.styles.showError"
                @input="forceUpdate($event)"
              />

              <!-- Datepicker -->
              <date-picker
                v-if="col.fieldType === 'date'"
                :col="col"
                :rowIndex="rowIndex"
                :colIndex="colIndex"
                :fieldStyles="fieldStyles"
                @forceupdate="forceUpdate"
              ></date-picker>

              <!-- Image(s) -->
              <image-upload
                v-if="col.fieldType === 'image'"
                :col="col"
                :rowIndex="rowIndex"
                :colIndex="colIndex"
                :fieldStyles="fieldStyles"
                :isPreview="isPreview"
              ></image-upload>

              <!-- Checkbox -->
              <v-checkbox
                v-if="col.fieldType === 'checkbox'"
                v-model="form.fields[col.label]"
                class="form-field"
                :label="currentForm.styles.showNameAbove ? '' : col.name"
                style="margin-top: 0px; padding-top: 0px"
                :hide-details="!currentForm.styles.showError"
                :style="fieldStyles"
                :rules="col.required ? validations.required : []"
                @change="$forceUpdate()"
              />

              <!-- Radio group -->
              <v-radio-group
                v-if="col.fieldType === 'radio'"
                v-model="form.fields[col.label]"
                class="form-field"
                style="margin-top: 0px"
                :label="currentForm.styles.showNameAbove ? '' : col.name"
                :hide-details="!currentForm.styles.showError"
                :rules="col.required ? validations.required : []"
                :style="fieldStyles"
                @change="$forceUpdate()"
              >
                <v-radio
                  v-for="(option, i) in getOptions(col.options)"
                  :key="i + 'a'"
                  :label="option"
                  :value="option"
                ></v-radio>
              </v-radio-group>

              <!-- Select -->
              <v-select
                v-if="col.fieldType === 'select'"
                v-model="form.fields[col.label]"
                class="form-field"
                :label="currentForm.styles.showNameAbove ? '' : col.name"
                dense
                clearable
                :items="getOptions(col.options)"
                :outlined="col.fieldStyle == 'outlined' ? true : false"
                :solo="col.fieldStyle == 'solo' ? true : false"
                :rules="col.required ? validations.required : []"
                :hide-details="!currentForm.styles.showError"
                :style="fieldStyles"
                @change="$forceUpdate()"
              >
              </v-select>

              <!-- MultiSelect -->
              <v-select
                v-if="col.fieldType === 'multiselect'"
                v-model="form.fields[col.label]"
                class="form-field"
                :label="currentForm.styles.showNameAbove ? '' : col.name"
                dense
                multiple
                small-chips
                deletable-chips
                :items="getOptions(col.options)"
                :outlined="col.fieldStyle == 'outlined' ? true : false"
                :solo="col.fieldStyle == 'solo' ? true : false"
                :rules="col.required ? validations.requiredArray : []"
                :hide-details="!currentForm.styles.showError"
                :style="fieldStyles"
                @change="$forceUpdate()"
              >
              </v-select>

              <!-- Submit btn -->
              <v-btn
                v-if="col.fieldType === 'submit'"
                :color="currentForm.styles.colors.fieldColor"
                class="white--text"
                @click.prevent="submit"
                >{{ col.text }}</v-btn
              >
            </div>
          </v-col>
        </v-row>

        <!-- Messages -->
        <v-row dense>
          <v-col cols="12">
            <p
              v-if="successMessage"
              class="success-message"
              :style="`color: ${currentForm.styles.colors.fieldColor}`"
            >
              {{ successMessage }}
            </p>
            <p
              v-if="errorMessage"
              class="error-message"
              :style="`color: ${currentForm.styles.colors.errorColor}`"
            >
              {{ errorMessage }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import DatePicker from "./DatePickerComponent.vue";
import validations from "../../validations";
import mixins from "@/mixins/mixins";
import ImageUpload from "./ImageComponent.vue";
import { v4 as uuidv4 } from "uuid";

export default {
  mixins: [mixins],

  components: {
    DatePicker,
    ImageUpload,
  },

  props: {
    isPreview: { type: Boolean, default: false },
    showGrid: { type: Boolean, default: false },
    attachTo: { type: String, default: "" },
  },

  data() {
    return {
      honeypot: null,
      submitStartTime: null,
      successMessage: "",
      errorMessage: "",
      validations,
      imageUrl: "",
    };
  },

  created() {
    this.getLogo();
    // Set form to empty values
    this.initProductionForm();
    // create unique key for saving images in same document
    this.sessionKey = uuidv4();
  },

  mounted() {
    this.submitStartTime = Date.now();
  },

  computed: {
    ...mapState("form", ["currentForm", "form"]),
    ...mapState("account", ["logo"]),

    rows() {
      return this.currentForm.rows;
    },

    sessionKey: {
      get() {
        return this.$store.state.form.sessionKey;
      },
      set(val) {
        this.$store.state.form.sessionKey = val;
      },
    },

    width() {
      const w = this.$store.state.form.currentForm.styles.width.split(" ");
      return {
        cols: w[0],
        sm: w[1],
        md: w[2],
        lg: w[3],
        xl: w[4],
      };
    },

    formPadding() {
      const p = this.$store.state.form.currentForm.styles.formPadding.split(" ");
      let val = "";
      if (p.length == 1) {
        val = `pa-${p[0]}`;
      } else if (p.length == 2) {
        val = `pt-${p[0]} pb-${p[0]} pr-${p[1]} pl-${p[1]}`;
      } else if (p.length == 3) {
        val = `pt-${p[0]} pr-${p[1]} pl-${p[1]} pb-${p[2]}`;
      } else if (p.length == 4) {
        val = `pt-${p[0]} pr-${p[1]} pb-${p[2]} pl-${p[3]}`;
      }

      return val;
    },

    colPadding() {
      return (padding) => {
        let val = "";
        if (padding) {
          const p = padding.split(" ");
          if (p.length == 1) {
            val = `pa-${p[0]}`;
          } else if (p.length == 2) {
            val = `pt-${p[0]} pb-${p[0]} pr-${p[1]} pl-${p[1]}`;
          } else if (p.length == 3) {
            val = `pt-${p[0]} pr-${p[1]} pl-${p[1]} pb-${p[2]}`;
          } else if (p.length == 4) {
            val = `pt-${p[0]} pr-${p[1]} pb-${p[2]} pl-${p[3]}`;
          }
        }

        return val;
      };
    },

    borderStyle() {
      const borderColor = this.$store.state.form.currentForm.styles.colors.borderColor;
      return {
        border: `1px solid ${borderColor}`,
        borderRadius: "4px",
        "z-index": 2,
      };
    },

    fieldStyles() {
      const fieldColor = this.$store.state.form.currentForm.styles.colors.fieldColor;
      const fieldColorR = parseInt(fieldColor.substring(1, 3), 16);
      const fieldColorG = parseInt(fieldColor.substring(3, 5), 16);
      const fieldColorB = parseInt(fieldColor.substring(5, 7), 16);

      return {
        "--fieldColorR": fieldColorR,
        "--fieldColorG": fieldColorG,
        "--fieldColorB": fieldColorB,
        "--fieldColor": fieldColor,
        "--textColor": this.$store.state.form.currentForm.styles.colors.textColor,
        "--errorColor": this.$store.state.form.currentForm.styles.colors.errorColor,
      };
    },
  },

  watch: {
    logo(obj) {
      if (obj && typeof obj == "object" && "key" in obj) this.updateImageUrl();
    },
  },

  methods: {
    ...mapActions("form", ["submitForm"]),
    ...mapMutations("form", ["resetFormFields", "initProductionForm", "setFormFieldValue"]),
    ...mapActions("account", ["getAccountLogo"]),
    ...mapMutations("account", ["setLogo"]),
    ...mapMutations("image", ["clearFormImages"]),

    async getLogo() {
      if (this.isPreview) {
        if (
          this.$store.state.account.currentUser.currentAccount.images &&
          this.$store.state.account.currentUser.currentAccount.images.length
        )
          this.setLogo(this.$store.state.account.currentUser.currentAccount.images[0]);
      } else {
        try {
          await this.getAccountLogo(this.currentForm.accId);
        } catch (err) {
          console.error(err);
        }
      }
    },

    updateImageUrl() {
      this.imageUrl = this.getLogoCloudFrontUrl(this.logo);
    },

    reloadImage() {
      setTimeout(() => {
        const imageUrl = this.getLogoCloudFrontUrl(this.logo);
        this.imageUrl = imageUrl;
      }, 2500);
    },

    showColumn(col) {
      let currentRule = null;

      this.currentForm.rules.forEach((rule) => {
        if (rule.targetFields.includes(col.label)) {
          currentRule = rule;
        }
      });

      if (!currentRule) return true;
      let show = true;

      // Get value of the rulefield
      const ruleFieldFormValue = this.form.fields[currentRule.ruleField];
      const ruleFieldFormValueBoolean =
        this.form.fields[currentRule.ruleField] || this.form.fields[currentRule.ruleField] === 0
          ? true
          : false;

      if (currentRule.ruleFieldValues && currentRule.ruleFieldValues.length > 0) {
        if (currentRule.ruleFieldBoolean) {
          if (currentRule.rule === "show") {
            if (Array.isArray(ruleFieldFormValue))
              show = this.isSubsetOfArray(currentRule.ruleFieldValues, ruleFieldFormValue);
            else show = currentRule.ruleFieldValues.includes(ruleFieldFormValue);
          }

          if (currentRule.rule === "hide") {
            if (Array.isArray(ruleFieldFormValue))
              show = !this.isSubsetOfArray(currentRule.ruleFieldValues, ruleFieldFormValue);
            else show = !currentRule.ruleFieldValues.includes(ruleFieldFormValue);
          }
        } else {
          if (currentRule.rule === "show") {
            if (Array.isArray(ruleFieldFormValue))
              show = !this.isSubsetOfArray(currentRule.ruleFieldValues, ruleFieldFormValue);
            else show = !currentRule.ruleFieldValues.includes(ruleFieldFormValue);
          }

          if (currentRule.rule === "hide") {
            if (Array.isArray(ruleFieldFormValue))
              show = this.isSubsetOfArray(currentRule.ruleFieldValues, ruleFieldFormValue);
            else show = currentRule.ruleFieldValues.includes(ruleFieldFormValue);
          }
        }
      } else {
        if (currentRule.rule === "show") {
          if (currentRule.ruleFieldBoolean === ruleFieldFormValueBoolean) show = true;
          else show = false;
        }

        if (currentRule.rule === "hide") {
          if (currentRule.ruleFieldBoolean === ruleFieldFormValueBoolean) show = false;
          else show = true;
        }
      }

      if (!show) {
        const field = col.label;
        const fieldValue = col.type == "array" ? [] : null;
        this.setFormFieldValue({ field, value: fieldValue });
      }
      return show;
    },

    isSubsetOfArray(arr1, arr2) {
      return arr1.some((value) => arr2.includes(value));
    },

    colsArr(string) {
      return string.split(" ");
    },

    getOptions(string) {
      return string.split(", ");
    },

    textOrEmailRules(col) {
      let rules = [];
      if (col.fieldType === "text") {
        if (col.required) {
          rules = [...validations.required];
        }
      } else if (col.fieldType === "email") {
        if (col.required) {
          rules = [...validations.required];
        }
        rules.push(...validations.email2);
      }
      return rules;
    },

    fraudDetection() {
      if (this.isPreview) return true;

      const honeypotValid = this.honeypot === null ? true : false;

      const submitTime = Date.now();
      const timeElapsed = submitTime - this.submitStartTime;
      const submitValid = timeElapsed > 6000;

      return honeypotValid && submitValid;
    },

    validateForm() {
      this.$refs.form.validate();
    },

    resetFormValidation() {
      this.$refs.form.resetValidation();
    },

    resetForm() {
      this.$refs.form.reset();
      this.resetFormFields();
      this.clearFormImages();
      this.sessionKey = uuidv4();
    },

    forceUpdate(val) {
      if (!val || (val && (String(val).length == 0 || String(val).length == 1))) {
        this.$forceUpdate();
      }
    },

    download(link) {
      window.open(link, "_blank");
    },

    async submit() {
      if (this.$refs.form.validate() && this.fraudDetection()) {
        if (this.isPreview) {
          this.showPopup("Lomakkeen validointi onnistui!", "success");
        } else {
          try {
            let itemId = null;

            if (this.attachTo === "apartment") {
              itemId = this.$route.params.apartmentId;
            } else if (this.attachTo === "rentalContract") {
              itemId = this.$route.params.contractId;
            }

            await this.submitForm({ itemId, attachTo: this.attachTo });

            this.successMessage = "Lomakkeen lähettäminen onnistui.";
            this.errorMessage = "";
            this.submitStartTime = Date.now();
            this.resetForm();
          } catch (err) {
            this.resetForm();
            this.errorMessage =
              "Lomakkeen lähettämisessä ilmeni virhe. Yritä myöhemmin uudelleen...";
            this.successMessage = "";
          }
        }
      } else {
        this.showPopup("Jokin meni pieleen, tarkista lomake.", "error");
      }
    },
  },
};
</script>

<style scoped src="../../assets/css/formStyles.css"></style>
