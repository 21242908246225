<template>
  <div>
    <v-file-input
      v-model="files"
      class="form-field"
      :multiple="col.multipleImages"
      show-size
      :label="currentForm.styles.showNameAbove ? '' : col.name"
      :solo="col.fieldStyle == 'solo' ? true : false"
      :outlined="col.fieldStyle == 'outlined' ? true : false"
      accept=".jpg,.jpeg,.png"
      dense
      persistent-hint
      :style="fieldStyles"
      prepend-icon="mdi-camera"
      :hint="`Max ${col.multipleImages ? `${maxFiles} kuvaa` : `1 kuva`}, .jpg .jpeg .png`"
      :loading="uploading"
      :disabled="fileInputIsDisabled"
      :rules="[isValidSelection]"
    ></v-file-input>

    <v-btn
      v-if="filesExist"
      small
      :color="currentForm.styles.colors.fieldColor"
      @click="uploadFiles"
      :loading="uploading"
      class="white--text mb-1"
      >Lataa</v-btn
    >

    <v-row v-if="filteredImages.length > 0" dense class="mt-1">
      <v-row dense justify="center">
        <v-col
          v-for="(image, index) in filteredImages"
          :key="index"
          style="
            padding: 0px;
            max-width: 100px;
            height: 100px;
            margin-left: 5px;
            margin-bottom: 10px;
            position: relative;
            border: 1px solid #ccc;
            box-sizing: border-box;
          "
        >
          <v-img
            :src="imageUrls[index]"
            @error="reloadImage(image, index)"
            max-height="100"
            style="width: 100%; height: 100%"
            contain
          ></v-img>

          <v-btn
            icon
            @click="deleteImage(image)"
            small
            :color="currentForm.styles.colors.errorColor"
            style="position: absolute; top: 0; right: 0"
          >
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-row>

    <!-- Error files -->
    <v-row class="mt-1 mb-1" dense v-if="filteredErrorFiles.length > 0" style="min-height: 120px">
      <v-row dense justify="center">
        <v-col
          v-for="(image, index) in filteredErrorFiles"
          :key="index"
          style="
            padding: 0px;
            max-width: 140px;
            height: 100px;
            margin-left: 5px;
            margin-bottom: 23px;
            position: relative;
            border: 1px solid #ccc;
            box-sizing: border-box;
          "
        >
          <v-img
            :src="image.src"
            max-height="100"
            style="width: 100%; height: 100%"
            contain
          ></v-img>
          <small :style="`color: ${currentForm.styles.colors.errorColor}`">{{
            image.errorMessage
          }}</small>

          <v-btn
            icon
            @click="removeErrorFile(image.src)"
            small
            :color="currentForm.styles.colors.errorColor"
            style="position: absolute; top: 0; right: 0"
          >
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-row>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import { mapMutations, mapState, mapActions } from "vuex";
import { apiAgent } from "../../services/apiAgent";
import axiosMethods from "@/mixins/axios";
import validations from "@/validations";

export default {
  mixins: [mixins],

  props: {
    col: { type: Object },
    colIndex: { type: Number },
    rowIndex: { type: Number },
    fieldStyles: { type: Object },
    isPreview: { type: Boolean, default: false },
  },

  data: function () {
    return {
      files: this.col.multipleImages ? [] : null,
      imageUrls: [],
      maxSize: 10000,
      maxFiles: 10,
      allowedTypes: ["image/jpeg", "image/jpg", "image/png"],
      uploading: false,
      uploadProgress: 0,
      validations,
    };
  },

  computed: {
    ...mapState("image", ["formImages", "errorFiles"]),
    ...mapState("form", ["currentForm", "form", "sessionKey"]),

    isValidSelection() {
      if (this.col.required) {
        if (this.col.multipleImages) {
          return this.filteredImages.length > 0 || "Valitse vähintään yksi kuva";
        } else {
          return this.filteredImages.length > 0 || "Pakollinen kenttä";
        }
      }

      return true;
    },

    fileInputIsDisabled() {
      if (this.col.multipleImages && this.filteredImages.length >= this.maxFiles) return true;
      else if (!this.col.multipleImages && this.filteredImages.length == 1) return true;
      else return false;
    },

    filteredImages() {
      return this.formImages.filter((el) => el.fieldLabel == this.col.label);
    },

    filteredErrorFiles() {
      return this.errorFiles.filter((el) => el.fieldLabel == this.col.label);
    },

    filesExist() {
      if (this.col.multipleImages && this.files.length > 0) {
        return true;
      } else if (!this.col.multipleImages && this.files) {
        return true;
      }
      return false;
    },

    accId() {
      const accId = this.currentForm.accId || this.currentForm.createdBy;
      return String(accId);
    },
  },

  watch: {
    "col.multipleImages"(val) {
      if (val) this.files = [];
      else this.files = null;
    },

    filteredImages: {
      handler: "updateImageUrls",
      immediate: true,
    },

    files(val) {
      if (Array.isArray(val)) {
        if (val.length + this.filteredImages.length > this.maxFiles) {
          this.showPopup(`Voit ladata kerralla ${this.maxFiles} kuvaa.`, "error");
        }
      }
    },
  },

  methods: {
    ...mapMutations("image", ["setFormImages", "removeErrorFile"]),
    ...mapActions("image", ["deleteFormImage"]),

    updateImageUrls() {
      this.imageUrls = this.filteredImages.map((img) => this.getFormImageCloudFrontUrl(img));
    },

    reloadImage(img, index) {
      setTimeout(() => {
        const imageUrl = this.getFormImageCloudFrontUrl(img);
        this.$set(this.imageUrls, index, imageUrl);
      }, 2000);
    },

    async uploadFiles() {
      if (this.isPreview) {
        return this.showPopup("Esikatselussa ei voi ladata kuvia", "error");
      }

      const newFiles = this.files;
      let files = [];

      // Validate length
      if (this.col.multipleImages && newFiles.length + this.filteredImages.length > this.maxFiles) {
        return this.showPopup(`Voit ladata kerralla ${this.maxFiles} kuvaa.`, "error");
      }

      // If single file
      if (!this.col.multipleImages && newFiles) {
        newFiles.fieldLabel = this.col.label;
        newFiles.src = URL.createObjectURL(newFiles);
        newFiles.errorMessage = this.validate(newFiles);
        files = [newFiles];
      } else if (this.col.multipleImages) {
        // Set files list that is shown to user (all files)
        files = [
          ...newFiles.map((el) => {
            el.fieldLabel = this.col.label;
            el.src = URL.createObjectURL(el);
            el.errorMessage = this.validate(el);
            return el;
          }),
        ];
      }

      // Set only valid files to uploadFiles array
      let validUpFiles = files.filter((el) => el.errorMessage === null);

      const formData = new FormData();

      if (validUpFiles.length > 0) {
        // Append to formData
        for (const file of validUpFiles) {
          formData.append("files", file, encodeURIComponent(file.name));
        }

        try {
          // Upload start
          this.uploading = true;

          const url = `/api/v1/public/aws/upload-form-images?formId=${this.currentForm._id}&accId=${this.accId}&sessionKey=${this.sessionKey}&fieldLabel=${this.col.label}`;

          // Upload to server and s3
          const res = await apiAgent.post(url, formData, {
            onUploadProgress: (event) => {
              this.uploadProgress = Math.floor(event.loaded * 100) / event.total;
            },
          });

          const dataString = JSON.stringify(res.data);

          // If error
          if (dataString.includes("ERROR:")) {
            const errorMessage = dataString.split("ERROR: ")[1];
            throw new Error(errorMessage);
          }

          // Set to store
          this.setFormImages(res.data.images);

          // End of uploading
          this.files = this.col.multipleImages ? [] : null;
          this.uploading = false;
          this.showPopup("Lataaminen onnistui", "success");
        } catch (err) {
          // End of uploading
          this.uploading = false;
          const error = await axiosMethods.validateRequest(err);
          this.showPopup(error, "error");
        }
      }

      // Set error images in their own array
      this.$store.state.image.errorFiles = [];
      files.forEach((el) => {
        if (el.errorMessage) this.$store.state.image.errorFiles.push(el);
      });

      this.files = this.col.multipleImages ? [] : null;
    },

    deleteImage(image) {
      this.deleteFormImage({
        imageKey: image.key,
        sessionKey: this.sessionKey,
        formId: this.currentForm._id,
        accId: this.accId,
      });
    },

    validate(file) {
      if (file.size / 1000 > this.maxSize) return "Tiedosto on liian suuri.";

      if (!this.allowedTypes.includes(file.type))
        return "Tiedostomuoto ei kelpaa. Lataa .jpg, .jpeg, .png tiedosto.";

      return null;
    },
  },
};
</script>

<style scoped src="../../assets/css/formStyles.css"></style>
